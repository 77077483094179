import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';

const IndexPage = () => (
  <Layout>

    <header className="mastheader">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.subGame}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subGamea}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="bsecond btn-second">
              สมัครเลย Click
            </a>
          </Scroll>
        </div>
      </div>
    </header>

  </Layout>
);

export default IndexPage;
